import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { XtrasService } from "./xtras.service";

interface AuthData {
    token: string,
    expiresAt?: string,
    user?: {
        id: number|string,
        name: string,
        email: string,
    }
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {

    private _auth = new BehaviorSubject<AuthData|undefined>(undefined);
	auth$ = this._auth.asObservable();

	get authValue(): AuthData|undefined {
		return this._auth.value;
	}
	set authValue(auth: AuthData|undefined) {
		this._auth.next(auth);
	}

    constructor(
        private router: Router,
        private xServices: XtrasService,
    ) {}

    getToken(): string|undefined {
		let auth:any = localStorage.getItem('auth') || undefined;
		if(auth && auth != '') {
            let _auth = this.xServices.filterEmptyParams(JSON.parse(auth));
			return (_auth['token'] ?? undefined);
		} else {
			return undefined;
		}
	}

    getAuth(): AuthData|undefined {
		let auth:any = localStorage.getItem('auth') || undefined;
		if(auth && auth != '') {
            let _auth = this.xServices.filterEmptyParams(JSON.parse(auth));
            if(!_auth['token']) return undefined;
			return {
                token: _auth['token'],
                expiresAt: _auth['expiresAt'] ?? undefined,
                user: _auth['user'] ?? undefined,
            };
		} else {
			return undefined;
		}
	}

    saveAuth(token: string, expiresAt?: string, user?: any, redirect: string = '/') {
        let auth: AuthData = {
            token: token,
            expiresAt: (expiresAt ?? undefined),
            user: (user ?? undefined),
        };
        let _auth = this.xServices.filterEmptyParams(auth);
        localStorage.setItem('auth', JSON.stringify(_auth));
        this.authValue = auth;
        this.router.navigateByUrl(redirect, {
            replaceUrl: true
        });
    }

    clearAuth() {
        localStorage.removeItem('auth');
        this.authValue = undefined;
    }

    logout() {
        this.clearAuth();
        this.router.navigateByUrl('/');
    }

    async getAuthPromise(): Promise<AuthData|undefined> {
        try {
            const auth = this.getAuth();
			if (!auth || !auth.token) {
				this.authValue = undefined;
				return undefined;
			}
            this.authValue = auth;
            return this.userData(auth);
        } catch (err) {
			console.log('errorAuth', err);
			this.authValue = undefined;
			return this.userData(undefined);
		}
    }

    userData(user?: AuthData) {
		if (!user) {
		  	this.logout();
		}
		return user;
	}
}